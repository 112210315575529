import React, { useState } from "react";
import { Toast } from "antd-mobile";
// import Image from "next/image";
import Image from "@/components/Image";
import style from "./index.module.css";
import CollectStar from "@/components/CollectStar";

interface IOrdinaryItemProps {
  item: ItemProps;
  onCollectStarClick?: (item: any) => void;
}

const OrdinaryItem: React.FC<IOrdinaryItemProps> = ({
  item,
  onCollectStarClick,
}) => {
  const peng = item?.activitytype?.includes(22);

  return (
    <>
      <div className={style.image_box}>
        <Image
          src={item.picList?.[0]}
          alt={item.serialName}
          className={style.img}
          width={343.79}
          height={230}
        />
        {peng && item?.promotionLabels ? (
          <div className={style.pengTag}>
            {item?.promotionLabels?.[0]?.name}
          </div>
        ) : null}
        {item.isVideo == 1 && <div className={style.play}></div>}
      </div>

      <div className={style.content}>
        <div className={style.title}>
          <div className={style.name}>{item.serialName}</div>
          <CollectStar
            item={item}
            unifiednumber={item.unifiednumber}
            ucarId={item.ucarId}
            isRecommend={item.isRecommend}
            onClick={onCollectStarClick}
          />
          {/* <div
            className={style.star}
            style={{
              backgroundImage: `url(https://static.taocheche.com/MWeb/components/product/${
                isRecommendCar ? "bright_star" : "star"
              }.png)`
            }}
            onClick={handStarClick}
          /> */}
        </div>
        <div className={style.txt}>
          {item.licensingYear}/{item.drivingMileageText}/{item.purchaseCityName}
        </div>
        <div className={style.price}>
          <div className={style.price_big}>{item.activityPriceText}</div>
          {item.loanFirstPay > 0 && (
            <div className={style.price_small}>首付{item.loanFirstPayText}</div>
          )}
        </div>
        {!peng && item.promotionLabels?.length > 0 && (
          <div className={style.tag_item}>
            <span className={style.tag}>{item.promotionLabels[0].name}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default OrdinaryItem;
